.userList{
    flex: 4;
    height: 90vh;
    width: 90%;
    
    }
    
    .editUser{
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        background-color: #3bb077;
        color:white;
        cursor: pointer;
        margin-right: 20px;
    }
    .deleteUser{
        color:red;
        cursor: pointer;
    }

    .topPart{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 50px;
    }