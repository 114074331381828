

.mainorderpage{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    flex-direction: row !important;
   
    
}

.myheading{
    padding:30px;
    margin:30px
}
.firstContainer{
    margin-right: 5px;
}