.widgetLarge{
    flex:2;
    padding: 20px; 
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);

}

.LgTable{
    width: 100%;
    /* border-spacing: 20px; */
}
.Lgth{
    text-align: left;
}
.title{
    font-size: 22px;
    font-weight: 600;
}
.user{
    display: flex;
    
}

.statusButton{
    padding: 5px 7px;
    border: none;
    border-radius: 10px;

}

.statusButton.approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.statusButton.declined{
    background-color: #fff0f1;
    color:#d95087
}
.statusButton.pending{
    background-color: #ebf1fe;
    color:#2a7ade
}
.topPart{
    display: flex;
    justify-content: space-between;
}
.editButton{
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    border: #ebf1fe;
    background-color: #2a7ade;
    color:white,
    
    
}

.sufficient{
    /* background-color: #e5faf2; */
    color: #3bb077;
}
.outOfStock{
    /* background-color: #fff0f1; */
    color:#d95087
}
.limited{
    /* background-color: #ebf1fe; */
    color:#2a7ade
}

.addDeposit{
    background-color: #0952f0;
    color: white !important;
    border-radius: 10px;
}