    .depositList{
        flex: 4;
        height: 90vh;
        flex-direction: col;
        width: 95%;
    
    }
    .addNewDeposit{
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        background-color: #39A2DB;
        color:white;
        cursor: pointer;
        margin-right: 20px;
    }
    .deleteDeposit{
        color:red;
        cursor: pointer;
    }
    .topPart{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
    }
    .complete{
        /* background-color: #e5faf2; */
        color: #3bb077;
    }
    .cancelled{
        /* background-color: #fff0f1; */
        color:#d95087
    }
    .pending{
        /* background-color: #ebf1fe; */
        color:#2a7ade
    }

    .featured{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .featureItem{
        flex: 1;
        margin: 0px 20px;
        padding: 30px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
        -webkit-box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
        -moz-box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
    }