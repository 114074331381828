.rootApp1{
    display: flex;
    /* flex-direction: column; */
    margin-left: 200px;
    /* background-color: blue; */
    margin-bottom: 30px;

}

.root{margin-top :100px}

.root1{margin-bottom: 100px;}

.child1{
    flex: 1;

}
.homeWidgets{
    display: flex;
    margin: 20px;

}

.featured{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.featureItem{
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
    -webkit-box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
    -moz-box-shadow: -6px 4px 83px -40px rgba(0,0,0,0.75);
}

#hide {
    display: none;
}
