

.mainorderpage{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    flex-direction: column;
}

.myheading{
    padding:30px;
    margin:30px
}