.Sufficient{
    /* background-color: #e5faf2; */
    color: #3bb077;
}
.OutofStock   {
    /* background-color: #fff0f1; */
    color:#d95087
}
.Limited {
    /* background-color: #ebf1fe; */
    color:#2a7ade
}