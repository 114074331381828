
.productList{
    flex: 4;
    height: 90vh;
    /* width: 0%; */
    
    }
    
    .editOrder{
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        background-color: #3bb077;
        color:white;
        cursor: pointer;
        margin-right: 20px;
    }
    .deleteOrder{
        color:red;
        cursor: pointer;
    }

    .topPart{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 50px;
    }

    .Approved{
        /* background-color: #e5faf2; */
        color: #3bb077;
    }
    .cancelled{
        /* background-color: #fff0f1; */
        color:#d95087
    }
    .pending{
        /* background-color: #ebf1fe; */
        color:#2a7ade
    }
    
    .bottomPart{
        height: 80vh;
    }

    #printOnly {
        display: none;
    }
    
    @media print { 
        #printOnly {
            display: block;
        }
        @page {
            size:landscape;
            size: 60mm 50mm;
            margin: 0.05;
          }
    }