.logoimg{
    margin-top: 5px;
}

.img{
    height: 50px;
    width: 110px;
    margin-top: 3px;
    border-radius: 10px;
    position: relative;
    background-color: whitesmoke;
    padding: 5px;
}


.topbar{
    width: 100%;
    height: 70px;
    background-color:#3F51B5;
    position: sticky;
    top: 1px;
    z-index: 999;
    border-bottom: 1px solid green;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    align-items: center;
    color:#d6dbe9;
    display: flex;
    justify-content: space-between;

}
.topbarIcons{
    display: flex;
    flex-direction: row;
}

.logout{
    color: white !important;
    border: 1px solid royalblue;
    padding:2px;
    border-radius: 34px 34px 34px 34px;
    margin-top: 15px;
}
.logout>p{
    color: white !important;
}
.logout:hover{
    transform: rotate(1deg) scale(1.1) translate(-12px,0px);
    border: 1px dotted #507eff;

}
.logo{
    font-weight: bold;
    font-size: 30px;
    margin: 3px;
    /* color:darkblue; */
    cursor: pointer;
}
.logotext{
    align-items: center;
    justify-content: center;
    padding-top: 5px;
}
.topLeft{
    color:white;
    display: flex;
    flex-direction: row;
}

.topCenter{
    text-decoration: none;
    display: flex;
    color: white;

}
.topCenter>a{
    text-decoration: none;
    margin-left: 10px;
}
.topCenter>a:visited{
    color: rgb(26, 24, 24) !important;
    /* border: 1px solid blue; */
}
.topCenter>a:active{
    border-bottom: 2px solid rgb(67, 95, 218);

}
.topCenter>a:hover{
    border-bottom: 2px solid rgb(67, 95, 218);

}
.dieselbtn{
    color:rgb(67, 95, 218); 
}
.topCenterFlex{
    display: flex;
    
    

}

.topCenterActive{
    border-bottom: 1px solid royalblue;
    font-weight: 600;
    color: white;
}

.topRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}