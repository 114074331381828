
.appRoot {
 /* display: flex; */
 /* background-color: yellow; */
 padding-left: 70px;
 margin-top: 10px;
}

.featuredTitle{
    font-size: 20px;
    
}
.featureContainer{
    margin:10px 0px;
    display:flex;
    align-items: center;

}
.total{
    font-size: 30px;
    font-weight: 600;
}
.Rate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.featureIcon{
    font-size: 14px;
    margin-left: 5px;
    color:red
}
.featureIcon-positive{
    
    color:green
}
.sub{
    font-size: 15px;
    color: grey;
}

